import React from 'react';
import * as styles from './PresentsSession.module.scss'

// component for each session video on /presents page

const PresentsSession = ({ number, videoURL, title, guests, moderator }) => {
  return (
    <>
      <div className={styles.keepaspect169}>
        <iframe
          src={videoURL}
          width="100%"
          height="100%"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          seamless="seamless"
          // allowFullScreen
          title="Breakthroughs 21 Opening"
        ></iframe>
      </div>
      <h2 className={styles.number}>SESSION {number}:</h2>
      <h3 className={styles.sessionTitle}>{title}</h3>
      <p className={styles.sectionHeader}>
        {guests.length > 1
          ? 'WITH SPECIAL GUESTS'
          : 'WITH'
        }
      </p>
      <div className={styles.guestContainer}>
        {guests?.map((guest) => {
          return (
            <React.Fragment key={guest.title}>
              <h3 className={styles.guestTitle}>{guest?.title}</h3>
              <p className={styles.subtitle}>{guest?.subtitle}</p>
            </React.Fragment>
          )
        })
        }
      </div>
      <div className={styles.modContainer}>
        {moderator &&
          <>
            <p className={styles.sectionHeader}>MODERATOR</p>
            <h3 className={styles.modTitle}>{moderator?.title}</h3>
            <p className={styles.subtitle}>{moderator?.subtitle}</p>
          </>
        }
      </div>
    </>
  )
};

export default PresentsSession;
