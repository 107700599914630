import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'gatsby';
// import Spinner from '../Spinner/Spinner'
import WelcomePopup from '../WelcomePopup/WelcomePopup'
import PresentsSession from '../PresentsSession/PresentsSession'
import { getUserFromCookie } from '../../utils/userCookies'
import * as styles from './EventsMain.module.scss'

// data migrated from ver 1 site - ToDo move to sanity

const tempSessionData = [
  {
    "number": 1,
    "videoURL": "https://player.vimeo.com/video/652058818?h=1b487d3123",
    "title": "The moral, ethical and human cost of the pandemic",
    "guests": [ 
      {
        "title": "Jessi Gold, M.D., M.S.",
        "subtitle": "Assistant Professor in the Department of Psychiatry at Washington University"
      }
    ]
  },
  {
    "number": 2,
    "videoURL": "https://player.vimeo.com/video/652062008?h=f8b73e1888",
    "title": "Are we Okay? Moving forward with purpose and dedication",
    "guests": [
      {
        "title": "Jonathan Slotkin, M.D., F.A.A.N.S.",
        "subtitle": "Chief Medical Officer, Contigo Health"
      },
      {
        "title": "Jessi Gold, M.D., M.S.",
        "subtitle": "Assistant Professor in the Department of Psychiatry at Washington University"
      },
      {
        "title": "Deb Kilday, M.S.N., R.N.",
        "subtitle": "Principal, Premier Women and Infant Services, Strategic Collaboratives"
      },
      {
        "title": "Jennifer H. Brady, M.D.",
        "subtitle": "Chief Executive Officer, Collaborative Physician Alliance | Atrium Health"
      },
      {
        "title": "Amy Robbins, M.D.",
        "subtitle": "Physician Executive, Premier Inc."
      }
    ],
    "moderator": {
      "subtitle": "Vice President and General Manager, Clinical Decision Support, Premier Inc.",
      "title": "Ryan Nellis"
    }
  },
  {
    "number": 3,
    "videoURL": "https://player.vimeo.com/video/652065428?h=ec043c7fb1",
    "title": "How data, AI and technology can make a difference for clinicians",
    "guests": [
      {
        "title": "Amy Robbins, M.D.",
        "subtitle": "Physician Executive, Premier Inc."
      },
      {
        "title": "Mathaeus Dejori",
        "subtitle": "Chief Data Scientist, Premier, Inc."
      },
      {
        "title": "Jennifer H. Brady, M.D.",
        "subtitle": "Chief Executive Officer, Collaborative Physician Alliance | Atrium Health"
      }
    ],
    "moderator": {
      "subtitle": "Vice President and General Manager, Clinical Decision Support, Premier Inc.",
      "title": "Ryan Nellis"
    }
  }
]

// svg arrow component
const RightArrow = () =>
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" fill="none" viewBox="0 0 8 13">
    <path stroke="#FFC532" strokeLinecap="round" strokeWidth="1.5" d="M1 1l5.5 5.5L1 12"></path>
  </svg>

// video sessions component - maps over docs and create jsx for each session
const Sessions = ({ docs }) => {
  let sessions = null

  // if (docs && !docs?.empty) {
  sessions = docs.map((session) => {
    return (
      <section id={`session${session.number}`} className={styles.session} key={session.number}>
        <div className={styles.sectionHeader}>
          <h3>SESSION {session.number}</h3>
        </div>
        <PresentsSession
          number={session.number}
          videoURL={session.videoURL}
          title={session.title}
          guests={session.guests}
          moderator={session?.moderator} />
      </section>
    )
  })
  // }
  return sessions
}

const EventsMain = (props) => {

  // const { data } = props

  const containerRef = useRef()
  // welcome form modal state
  const [isOpen, setIsOpen] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  // const [sessionData, setSessionData] = useState();

  // scroll to session - relies on smoothscroll-polyfill
  const onClick = (id, event) => {
    event.preventDefault()
    const sessionDiv = document.querySelector(`#${id}`)
    const y = sessionDiv.getBoundingClientRect().top + window.pageYOffset - 15
    window.scrollTo({ top: y, behavior: 'smooth' })
  }

  useEffect(() => {
    // show popup if no cookie exists
    if (!isOpen && !getUserFromCookie()) {
      setIsOpen(true)
    }
  }, [isOpen]);

  // if (isLoading) {
  //   return (
  //     <div className={styles.loading}>
  //       <Spinner />
  //     </div>
  //   )
  // }

  return (
    <div className={styles.container} ref={containerRef}>

      <main className={styles.main}>
        {/* videos */}
        <div className={styles.videoSections}>
          {/* video session nav */}
          <nav className={styles.videoLinks} aria-labelledby="video-session-navigation">
            <div className={styles.linkWrapper}>
              <div className={styles.scrollText}>SCROLL TO:</div>
              <button onClick={(e) => onClick('session1', e)}>SESSION 1</button>
              <button onClick={(e) => onClick('session2', e)}>SESSION 2</button>
              <button onClick={(e) => onClick('session3', e)}>SESSION 3</button>
            </div>
          </nav>
          {/* sessions 1,2,3 */}
          {/* <Sessions docs={sessionData} /> */}
          <Sessions docs={tempSessionData} />

          {/* previous events */}
          {/* <section className={styles.previousEvents}>
            <div className={styles.sectionHeader}>
              <h3>PREVIOUS EVENTS ON DEMAND</h3>
            </div>
            <PreviousEvents />
          </section> */}

        </div>
        {/* connect */}
        <section className={styles.connect}>
          <h2>CONNECT WITH US.</h2>
          <p className={styles.connectText}>Our team of passionate healthcare experts are standing by to answer your questions.</p>
          <Link to="/contact">
            <span>GET IN TOUCH</span> <RightArrow />
          </Link>
        </section>
      </main>
      <WelcomePopup
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        appContainerRef={containerRef.current} />
    </div>
  )

}

export default EventsMain