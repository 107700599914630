import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import FilterBreak from '../components/FilterBreak/FilterBreak';
import EventsMain from '../components/EventsMain/EventsMain'
import pincailogo from '../assets/images/pincai-text-logo-white.svg'
import EventsHero from '../components/EventsHero/EventsHero';
import * as styles from '../styles/events.module.scss'
import Seo from '../components/Seo/Seo';


export const query = graphql`
  query events {
    events: sanityEventsPage {
      browserTitle
      seoData {
        title
        description
        image {
          asset {
            url
          }
        }
      }
      heroDescription
      heroTime
      heroTitle
    }
  }
`


const PresentsTemplate = props => {
  const { data } = props
  // console.log(data);
  return (
    <Layout>
      <div className={styles.wrapper}>
        <h1 className={styles.header}>
          <span><img src={pincailogo} alt="Pinc AI Logo" /></span>
          <span>Events</span>
        </h1>

        <FilterBreak />

        <EventsHero data={data.events} />

        <EventsMain />
      </div>

    </Layout>
  )

}

export default PresentsTemplate

export const Head = (props) => {
  const { data } = props;
  return (
    <Seo title={data.events.browserTitle} seo={data.events.seoData} />
  )
}