import cookies from 'js-cookie'

// v3.0 call JSON.parse(Cookies.get('foo')) if value was an object
// await cookie result so /present page can launch popup

const cookieName = 'premier-pincai'

export const getUserFromCookie =  () => {
  const cookie =  cookies.get(cookieName)
  if (!cookie) {
    return null
  }
  if (cookie)
  return JSON.parse(cookie)
}

// v3.0 provide a string for user or provide object and call JSON.stringify(user)

export const setUserCookie = (user) => {
  // console.log(user);
  cookies.set(cookieName,JSON.stringify(user), {
    // set cookie expiry to one day
    expires: 1,
    // path where the cookie is visible
    path: '/events'
  })
}

export const removeUserCookie = () => cookies.remove(cookieName)
