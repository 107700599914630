import React from 'react';
import Modal from 'react-modal'
import PincPresents from '../svg/PincPresents'
import WelcomeForm from '../WelcomeForm/WelcomeForm';
import * as styles from './WelcomePopup.module.scss'

const modalStyles = {
  content: {
    top: 'auto',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    paddingTop: 118,
    marginRight: '-50%',
    paddingBottom: 118,
    transform: 'translate(-50%, 0)',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: 0,
    padding: 0,
    overflow: 'visible',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.71)',
    zIndex: 10000
  },
};



const WelcomePopup = ({ isOpen, onClose, appContainerRef }) => {

  // important for users of screenreaders that other page content be hidden
  Modal.setAppElement(appContainerRef)

  return (
    <Modal
      closeTimeoutMS={200} // needs to match css transition duration
      isOpen={isOpen}
      // onRequestClose={onClose}
      style={modalStyles}>
      <div className={`${styles.container}`}>
        <div className={styles.logo}>
          <PincPresents dark />
        </div>
        <div className={styles.form}>
          <h1 className={styles.h1}>WELCOME TO PINC AI EVENTS.</h1>
          <p className={styles.helperText}>Please enter your name, email address, and organization to watch the live stream.</p>
          <WelcomeForm closeForm={onClose}/>
        </div>
      </div>
    </Modal>
  )
};

export default WelcomePopup;
