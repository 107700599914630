// extracted by mini-css-extract-plugin
export var connect = "EventsMain-module--connect--306fa";
export var connectText = "EventsMain-module--connectText--af8db";
export var container = "EventsMain-module--container--cbf94";
export var infoSection = "EventsMain-module--infoSection--0aef2";
export var linkWrapper = "EventsMain-module--linkWrapper--0a3ed";
export var loading = "EventsMain-module--loading--1620e";
export var main = "EventsMain-module--main--c65e8";
export var previousEvents = "EventsMain-module--previousEvents--19156";
export var schedule = "EventsMain-module--schedule--a3a34";
export var scrollText = "EventsMain-module--scrollText--326d9";
export var sectionHeader = "EventsMain-module--sectionHeader--a8c95";
export var session = "EventsMain-module--session--16de5";
export var subtitle = "EventsMain-module--subtitle--0909c";
export var title = "EventsMain-module--title--8bdcb";
export var videoLinks = "EventsMain-module--videoLinks--7df76";
export var videoSections = "EventsMain-module--videoSections--d2e73";