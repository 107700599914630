import React, { useState } from 'react';
// firebase V9
// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
// import { collection, addDoc } from "firebase/firestore";
import { useForm } from "react-hook-form";
import { setUserCookie } from '../../utils/userCookies';
import { customAlphabet } from 'nanoid'
import Spinner from '../Spinner/Spinner'
import * as styles from './WelcomeForm.module.scss'

// const firebaseConfig = {
//   apiKey: "AIzaSyAV4fgBaFzhYi5_w46zijUx_9Y4t8siWU0",
//   authDomain: "pinc-ai-phase2.firebaseapp.com",
//   projectId: "pinc-ai-phase2",
// };

// const app = initializeApp(firebaseConfig);
// const db = getFirestore();

/* 
  react-hook-form provides validation
  * isValid doesn't work in onTouched mode :-(
  * isValid is a reliable way to enable submit button
  * to solve - changed mode to onChange and added validation delay so errors are not shown as soon as typing is started
*/


// use known set of characters for token
const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz-', 10)

const WelcomeForm = ({ closeForm }) => {

  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isDirty,
      isValid
    }
  } = useForm({
    mode: 'onChange',
    delayError: 2000,
    reValidateMode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      org: ""
    }
  })
  const [isLoading, setIsLoading] = useState(false);


  const onSubmit = async (data) => {
    // console.log(`data`, data);
    let token = nanoid()
    // console.log(token);
    setIsLoading(true)
    await writeFormData(token, data)
    // set cookie to enable access
    setUserCookie(
      {
        "id": token
      }
    )
    closeForm()
  }

  // write form data
  const writeFormData = async (token, data) => {

    try {
      // const docRef = await addDoc(collection(db, 'eventAttendees'), {
      //   accessToken: token,
      //   createdOn: new Date(),
      //   firstName: data.firstName,
      //   lastName: data.lastName,
      //   email: data.email,
      //   org: data.org
      // })
      // console.log('written', docRef.id);
    } catch (error) {
      console.error(error)
    }
    return
  }

  // console.log(errors, isDirty, isValid);

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.row}>
        {/* first name */}
        <input
          type="text"
          name="first-name"
          id="first-name"
          autoComplete="given-name"
          className={styles.input}
          placeholder="FIRST NAME"
          // autoFocus
          required
          {...register('firstName', {
            required: "Please enter your first name"
          })}
        />
        <label htmlFor='first-name' hidden>FIRST NAME</label>
        {errors?.firstName && <span className={styles.errors}>{errors.firstName?.message}</span>}
      </div>
      <div className={styles.row}>
        {/* last name */}
        <input
          type="text"
          name="last-name"
          id="last-name"
          autoComplete="family-name"
          className={styles.input}
          placeholder="LAST NAME"
          required
          {...register('lastName', {
            required: "Please enter your last name"
          })}
        />
        <label htmlFor='last-name' hidden>LAST NAME</label>
        {errors?.lastName && <span className={styles.errors}>{errors.lastName?.message}</span>}
      </div>
      <div className={styles.row}>
        {/* email */}
        <input
          type="email"
          name="email"
          id="email"
          autoComplete="username"
          className={styles.input}
          placeholder="EMAIL ADDRESS"
          required
          {...register('email', {
            required: "Please enter your email address",
            pattern: { value: /^\S+@\S+$/i, message: "Please verify your email address is correct" }
          })}
        />
        <label htmlFor='email' hidden>EMAIL ADDRESS</label>
        {errors?.email && <span className={styles.errors}>{errors.email?.message}</span>}
      </div>
      <div className={styles.row}>
        {/* organization */}
        <input
          type="text"
          name="organization"
          id="organization"
          autoComplete="organization"
          className={styles.input}
          placeholder="ORGANIZATION"
          required
          {...register('org', {
            required: "Please enter your organization",
          })}
        />
        <label htmlFor='organization' hidden>ORGANIZATION</label>
        {errors?.org && <span className={styles.errors}>{errors.org?.message}</span>}
      </div>
      <button
        type='submit'
        className={styles.submit}
        disabled={!isDirty || !isValid}>
        {isLoading && <Spinner size="20" />} WATCH EVENT
      </button>
    </form>
  )
};

export default WelcomeForm;
