import React from 'react'
import PincPresents from '../svg/PincPresents'
import * as styles from './EventsHero.module.scss'

const EventsHero = (props) => {

  const { data } = props

  const { heroTitle, heroDescription, heroTime } = data

  return (
    <section className={styles.container}>
      <div className={styles.logo}>
        <PincPresents />
      </div>
      <h1 className={styles.h1}>{heroTitle}</h1>
      <p className={styles.desc}>{heroDescription}</p>
      <p className={styles.time}>{heroTime}</p>
    </section>
  )

}

export default EventsHero